import { Col,Grid } from '@koaly/grid';
import {
  CollapsibleCard,
  Container,
  ContentMedia,
  ContentUsps,
  Hero,
  Picture,
  TitleContent} from '@koaly/koaly-ui';
import {
  CalendarIllustrative,
  EmergencyIllustrative,
  SettingsIllustrative,
} from '@koaly/koaly-ui-illustrations';
import { Footer } from 'components/Footer/Footer';
import { FooterCTAWaitingList } from 'components/FooterCTAWaitingList/FooterCTAWaitingList';
import { RegisterForm } from 'components/RegisterForm/RegisterForm';
import { TopBar } from 'components/TopBar/TopBar';
import { NextPage } from 'next';
import Head from 'next/head';
import React from 'react';

import {
  heroImage,
} from '../assets';
import { Link } from '../components/Link/Link';
import { PricePlan } from '../components/PricePlan/PricePlan';
import { responsiveToSource } from '../helpers/responsiveToSources';

export const Index: NextPage = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Een prettige praktijkervaring begint online - Koaly</title>
        <meta
          name="description"
          content="De slimste website voor huisartspraktijken komt binnenkort beschikbaar. Schrijf je alvast gratis in!"
        />
        <meta
          name="og:description"
          content="De slimste website voor huisartspraktijken komt binnenkort beschikbaar. Schrijf je alvast gratis in!"
        />
      </Head>
      <Container background={true} fadeOut={true} backgroundFigures={true} variant="secondary">
        <TopBar variant="secondary" />
        <Hero
          variant="secondary"
          title="Een prettige praktijkervaring begint online"
          description={
            'De slimste website voor huisartspraktijken komt binnenkort beschikbaar. Schrijf je alvast gratis in, krijg een seintje wanneer we live gaan en profiteer de eerste 3 maanden van 50% korting.'
          }
          media={
            <Picture
              {...responsiveToSource(heroImage)}
              lazyLoading={true}
              alt="Koaly praktijkwebsite op een beeldscherm"
            />
          }
        >
          <RegisterForm />
        </Hero>
      </Container>
      <Container>
        <ContentUsps
          title={'Ervaar het gemak van een Koaly \n praktijkwebsite'}
          usps={[
            { title: 'Makkelijk in beheer', illustration: SettingsIllustrative },
            { title: 'Minder administratie', illustration: CalendarIllustrative },
            { title: 'Doorlopende service', illustration: EmergencyIllustrative },
          ]}
        />
        <ContentMedia
          spacing={true}
          title="Heldere service via een prettige website"
          description="Koaly biedt volledige praktijkwebsites. Als huisarts ben je met een Koaly praktijkwebsite minder tijd kwijt aan websitebeheer en admistratie, terwijl de service voor patiënten optimaal is."
          buttons={[{ label: 'Bekijk functionaliteiten', href: '/functionaliteiten', variant: 'secondary' }]}
          mediaFit="contain"
          media={
            <img
              loading="lazy"
              src="/assets/illustrations/product.svg"
              alt="Illustratie van het product"
            />
          }
        />
        <ContentMedia
          spacing={true}
          title="Jouw herkenbare praktijk"
          description="Creëer het vertrouwde gevoel van jouw huisartsenpraktijk, online. Upload het logo en kies de kleuren van jouw praktijk. Bepaal vervolgens welke functionaliteiten je wil activeren op de praktijkwebsite."
          buttons={[{ label: 'Bekijk functionaliteiten', href: '/functionaliteiten', variant: 'secondary' }]}
          mediaPosition="left"
          mediaFit="contain"
          media={
            <img
              loading="lazy"
              src="/assets/illustrations/herkenbaarheid.svg"
              alt="Illustratie met kleurenpaletten en logo upload knop"
            />
          }
        />
        <ContentMedia
          spacing={true}
          title="Toegankelijk en veilig voor iedereen"
          description="De communicatie tussen patiënt en praktijk is beveiligd. Daarnaast voldoet jouw praktijkwebsite altijd aan de privacywet (denk aan AVG) en is er rekening gehouden met patiënten met een beperking."
          buttons={[{ label: 'Bekijk functionaliteiten', href: '/functionaliteiten', variant: 'secondary' }]}
          mediaFit="contain"
          media={
            <img
              loading="lazy"
              src="assets/illustrations/beveiligd.svg"
              alt="Illustratie over veiligheid, ssl, encrypted etc"
            />
          }
        />
        <ContentMedia
          spacing={true}
          title="Doorlopende service en support"
          description="Samen met jou blijven we innoveren. Ieder kwartaal ontvang je onze planning met aankomende functionaliteiten. Terwijl jouw praktijkwebsite zich blijft doorontwikkelen, blijft de prijs hetzelfde."
          mediaFit="contain"
          mediaPosition="left"
          media={
            <img
              loading="lazy"
              src="assets/illustrations/planning.svg"
              alt="Illustratie over roadmap elk kwartaal"
            />
          }
        />
      </Container>
      <Container background={true} variant="secondary">
        <PricePlan />
      </Container>
      <Container>
        <TitleContent title="Veelgestelde vragen" spacing={true} align="center" titleSpaceAfter={24}>
          <Grid gutter={10}>
            <Col width={{ xs: 1, s: 1 / 2 }}>
              <CollapsibleCard
                title="Wanneer kan ik Koaly gebruiken?"
                ariaLabelOpenButton="Klap deze vraag uit om meer over te lezen."
                ariaLabelCloseButton="Klap deze vraag in."
                spaceAfter={10}
              >
                Wij lanceren Koaly na verwachting in Q1 2022. Schrijf je in en ontvang een berichtje wanneer je jouw praktijkwebsite
                kan installeren.
              </CollapsibleCard>
              <CollapsibleCard
                title="Wat doe ik met (de data) van mijn huidige website?"
                ariaLabelOpenButton="Klap deze vraag uit om meer over te lezen."
                ariaLabelCloseButton="Klap deze vraag in."
                spaceAfter={10}
              >
                Data van jouw oude website kan worden overgezet naar de nieuwe website. Wij kunnen jou hierbij eventueel
                assisteren.
              </CollapsibleCard>
              <CollapsibleCard
                title="Hoe weten jullie welke functionaliteiten patiënten willen?"
                ariaLabelOpenButton="Klap deze vraag uit om meer over te lezen."
                ariaLabelCloseButton="Klap deze vraag in."
                spaceAfter={10}
              >
                We hebben onderzoek gedaan naar de wensen en ervaringen van patiënten. Hiermee hebben wij in kaart
                gebracht waar patiënten nu tegenaan lopen op praktijkwebsites, en wat zij nog
                missen. <Link to="/functionaliteiten">Meer over functionaliteiten</Link>
              </CollapsibleCard>
            </Col>
            <Col width={{ xs: 1, s: 1 / 2 }}>
              <CollapsibleCard
                title="Hoe weten jullie wat belangrijk is voor huisartspraktijken?"
                ariaLabelOpenButton="Klap deze vraag uit om meer over te lezen."
                ariaLabelCloseButton="Klap deze vraag in."
                spaceAfter={10}
              >
                Als huisarts of praktijkmanager wil je geen tijd verliezen aan websitebeheer en onnodige administratie.
                Onze prioriteit ligt daarom bij het aanbieden van een zo volledig, maar eenvoudig mogelijke
                beheeromgeving, die uitstekende service biedt aan jouw patiënten. <br />
                <Link to="/functionaliteiten">Meer over functionaliteiten</Link>
              </CollapsibleCard>
              <CollapsibleCard
                title="Welke innovaties kan ik verwachten?"
                ariaLabelOpenButton="Klap deze vraag uit om meer over te lezen."
                ariaLabelCloseButton="Klap deze vraag in."
                spaceAfter={10}
              >
                Een overzicht van aankomende innovaties vind je <Link to="/functionaliteiten#roadmap">vind je
                hier.</Link>
              </CollapsibleCard>
            </Col>
          </Grid>
        </TitleContent>
      </Container>
      <Container background={true} variant="secondary">
        <FooterCTAWaitingList />
      </Container>
      <Container>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

export default Index;
