import { Button, ButtonGroup, Heading, Paragraph, TitleContent } from '@koaly/koaly-ui';
import { withExtra } from '@koaly/with-extra';
import React, { useState } from 'react';

export const PricePlan = withExtra<{}>(() => {
  const [product, setProduct] = useState<1 | 2>(1);
  const onClick = (id) => {
    return () => {
      setProduct(id);
    };
  };
  return (
    <TitleContent
      spacing={true}
      variant="secondary"
      title="Kies jouw pakket"
      align="center"
      id="prijs-plan"
      buttons={[{ label: 'Blijf op de hoogte', href: '#inschrijven', variant: 'primary' }]}
    >
      <ButtonGroup glue={true} spaceAfter={20}>
        <Button variant={product === 1 ? 'primary' : 'secondary'} elevation={1} onClick={onClick(1)} size="small">
          Eén huisartsenpraktijk
        </Button>
        <Button variant={product === 2 ? 'primary' : 'secondary'} elevation={1} onClick={onClick(2)} size="small">
          Meerdere praktijken
        </Button>
      </ButtonGroup>
      {product === 1 && (
        <React.Fragment>
          <Heading size="h4" as="h3" variant="secondary" spaceAfter={4}>De eerste 3 maanden van €80,- voor €40,-</Heading>
          <Paragraph variant="secondary" spaceAfter={8}>€200,- eenmalige opstartkosten.</Paragraph>
          <Paragraph variant="secondary">
            Een volledige website voor jouw huisartspraktijk, up-to-date <br />
            volgens privacy- en veiligheidsregels, doorlopende technische en <br />
            functionele updates.
          </Paragraph>
        </React.Fragment>
      )}
      {product === 2 && (
        <React.Fragment>
          <Heading variant="secondary" size="h4" as="h3" spaceAfter={8}>In ontwikkeling</Heading>
          <Paragraph variant="secondary">
            Meerdere websites voor huisartspraktijken onder één beheer, <br />
            up-to-date volgens privacy- en veiligheidsregels, doorlopende <br />
            technische en functionele updates.
          </Paragraph>
        </React.Fragment>
      )}
    </TitleContent>
  );
}, { name: 'PricePlan' });
